import { api } from './api';
import { jwtDecode } from 'jwt-decode';
export const adminList = [
  'officialaakashbhardwaj@gmail.com',
  'louisvelazquezbp@gmail.com'
];
// export const logoWrapper = (logo, item) => {
//   if (item) {
//     console.log(new Date(item.timestamp));
//   }
//   return logo?.replace(
//     /api\.pecunovus\.net|api\.hootdex\.net/g,
//     'dev.hootdex.org'
//   );
// };
export const logoWrapper = (logo, item) => {
  // if (item) {
  //   const itemDate = new Date(item.timestamp);
  //   // Check if the date is after March 2024
  //   const cutoffDate = new Date('2024-04-01T00:00:00Z');
  //   if (itemDate >= cutoffDate) {
  //     return logo?.replace(/api\.hootdex\.net/g, 'api.pecunovus.net');
  //   }
  // }

  // Apply the replacement logic if the date is before April 2024
  return logo?.replace(
    /mhiservers3\.com|api\.hootdex\.net/g,
    'api.pecunovus.net'
  );
};

export function convertToInternationalCurrencySystem(labelValue) {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(5) + 'b'
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(5) + 'm'
    : // Three Zeroes for Thousands
    Math.abs(Number(labelValue)) >= 1.0e3
    ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(5) + 'k'
    : Math.abs(Number(labelValue))
    ? Math.abs(Number(labelValue))?.toFixed(5)
    : '0.00';
}

export function clearAllCookies() {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
  }
  localStorage.removeItem('hootdex_secretcookie_wallet');
  window.location.reload();
}

export function getFormattedTime(timestampString) {
  const timestamp = new Date(timestampString);
  timestamp.setHours(timestamp.getHours());
  return timestamp;
}
export function capitalizeFirstLetter(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}
export const removeDuplicatedToken = (allData) => {
  // Helper function to check if there are any duplicates left
  const hasDuplicates = () => {
    for (let i = 0; i < allData.length; i++) {
      for (let j = i + 1; j < allData.length; j++) {
        if (allData[i].symbol === allData[j].symbol) {
          return true;
        }
      }
    }
    return false;
  };

  // Recursive function to remove duplicates
  const removeDuplicates = () => {
    for (let i = 0; i < allData.length; i++) {
      for (let j = i + 1; j < allData.length; j++) {
        if (allData[i].symbol === allData[j].symbol) {
          allData[i].wrapAmount += allData[j].wrapAmount;
          allData[i].initialFinal += allData[j].initialFinal;
          allData.splice(j, 1); // Remove the duplicate element
          return removeDuplicates(); // Recursively check for more duplicates
        }
      }
    }
  };

  while (hasDuplicates()) {
    removeDuplicates(); // Keep removing duplicates until none are left
  }

  return allData;
};

export const filterTokenListBySearchStr = (list, searchStr) => {
  if (searchStr && searchStr !== '') {
    return list?.filter(
      (e) =>
        e.symbol.toLowerCase().includes(searchStr.toLowerCase()) ||
        e.name.toLowerCase().includes(searchStr.toLowerCase())
    );
  } else {
    return list;
  }
};

export function priceWrapper(labelValue) {
  return labelValue > 1 ? labelValue?.toFixed(5) : labelValue?.toFixed(5);
}

export function PecuPriceHttpRequest(dispatch, actionType) {
  api
    .get(`/wallet/get_current_index_coin`)
    .then((res) => {
      const price = res.data[0].value;
      dispatch({ type: actionType, price: price });
    })
    .catch((err) => console.log(err));
}

export const get_crypto_Data = (dispatch, actionType) => {
  api.get(`/crypto/index`).then((res) => {
    dispatch({ type: actionType, cryptoData: res.data });
  });
};
//j

export const fetchProjectTokens = (dispatch, actionType) => {
  api
    .get(`/hootdex/all-project-token`)
    .then((res) => {
      res?.data?.data?.sort((a, b) =>
        a?.token_symbol[0]?.localeCompare(b?.token_symbol[0])
      );
      dispatch({ type: actionType, projectTokens: res.data.data });
    })
    .catch((err) => {
      // console.log(err);
    });
};
export const fetchFanTokens = (dispatch, actionType) => {
  api
    .get(`/hootdex/all-fan-token`)
    .then((res) => {
      res?.data?.data?.sort((a, b) =>
        a?.token_symbol[0]?.localeCompare(b?.token_symbol[0])
      );
      dispatch({ type: actionType, fanTokens: res.data.data });
    })
    .catch((err) => {
      // console.log(err);
    });
};
export const fetchStocks = (dispatch, actionType, page, perPage) => {
  api
    .get(`/crypto/get_all_stocks?page=${page}&perPage=${perPage}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: actionType, stocks: res.data });
      }
    })
    .catch((err) => {
      // console.log(err);
    });
};
export const fetchCommodity = (dispatch, actionType) => {
  api
    .get(`/crypto/get_all_commodity`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: actionType, commodities: res.data.tokens });
      }
    })
    .catch((err) => {
      // console.log(err);
    });
};

export const fetchForex = (dispatch, actionType, page, perPage) => {
  api
    .get(`/crypto/get_all_forex?page=${page}&perPage=${perPage}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: actionType, forex: res.data });
      }
    })
    .catch((err) => {
      // console.log(err);
    });
};
export const fetchDerivatives = (dispatch, actionType, page, perPage) => {
  api
    .get(`/crypto/get_all_derivatives?page=${page}&perPage=${perPage}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: actionType, derivatives: res.data });
      }
    })
    .catch((err) => {
      // console.log(err);
    });
};
export const fetchHedges = (dispatch, actionType, page, perPage) => {
  api
    .get(`/hedge?page=${page}&perPage=${perPage}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: actionType, hedges: res.data });
      }
    })
    .catch((err) => {
      // console.log(err);
    });
};
export const fetchDat = (dispatch, actionType, page, perPage) => {
  api
    .get(`/dat?page=${page}&perPage=${perPage}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: actionType, dat: res.data });
      }
    })
    .catch((err) => {
      // console.log(err);
    });
};
export const fetchSynthetics = (dispatch, actionType) => {
  api
    .get(`/crypto/get_all_synthetics??page=1&perPage=100`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: actionType, synthetics: res.data.tokens });
      }
    })
    .catch((err) => {
      // console.log(err);
    });
};
export const fetchSyntheticsPrice = (dispatch, actionType) => {
  api
    .get(`/crypto/get_all_synthetics_price?page=1&perPage=20`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: actionType, syntheticsPrice: res.data.tokens });
      }
    })
    .catch((err) => {
      // console.log(err);
    });
};

export const fetchDerivativesPrice = (dispatch, actionType, page, perPage) => {
  api
    .get(`/crypto/get_all_pairs_price?page=${page}&perPage=${perPage}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: actionType, derivativesPrice: res.data.tokens });
      }
    })
    .catch((err) => {
      // console.log(err);
    });
};

export const fetchStableCoin = (dispatch, actionType) => {
  api
    .get(`/crypto/get_all_stable_coin`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: actionType, stableCoin: res.data.tokens });
      }
    })
    .catch((err) => {
      // console.log(err);
    });
};
export const fetchStableCoinPrice = (dispatch, actionType) => {
  api
    .get(`/crypto/get_all_stablecoin_price?page=1&perPage=20`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: actionType, stableCoinPrice: res.data.tokens });
      }
    })
    .catch((err) => {
      // console.log(err);
    });
};

export const fetchWrapTokens = (dispatch, actionType) => {
  api
    .get(`/wallet/get_all_tokens_wrap_new`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: actionType, wrapTokens: res.data.tokens });
      }
    })
    .catch((err) => {
      // console.log(err);
    });
};

export const fetchTokenList = (dispatch, actionType) => {
  api
    .get(`/exchange/token-list`)
    .then((res) => {
      if (res.data) {
        dispatch({ type: actionType, tokenList: res.data });
      }
    })
    .catch((err) => {
      // console.log(err);
    });
};

export const fecthHoldingTokens = (dispatch, actionType) => {
  api
    .get(`/wallet/get_all_tokens_holding`)
    .then((res) => {
      if (res.data.status) {
        res.data?.tokens?.sort((a, b) =>
          a?.token_symbol[0].localeCompare(b?.token_symbol[0])
        );
        dispatch({ type: actionType, holdingTokens: res.data.tokens });
      }
    })
    .catch((err) => {
      // console.log(err);
    });
};

export const fecthDelistedTokens = (dispatch, actionType) => {
  api
    .get(`/hootdex/getDelistedToken`)
    .then((res) => {
      // console.log(res.data.token, 'DelistedTokens');
      if (res.data.status) {
        // res.data?.token?.sort((a, b) =>
        //   a?.token_symbol[0].localeCompare(b?.token_symbol[0])
        // );
        dispatch({ type: actionType, deListed: res.data.token });
      }
    })
    .catch((err) => {
      // console.log(err);
    });
};

export const fetchPools = (dispatch, actionType, page, perPage) => {
  api
    .get(`/hootdex/liquidity-pool-info-all?page=${page}&perPage=${perPage}`)
    .then((res) => {
      dispatch({
        type: actionType,
        liquidityPools: res.data
      });
    })
    .catch((err) => {
      // console.log(err);
    });
};
export const fetchPoolsStableCoin = (dispatch, actionType, page, perPage) => {
  api
    .get(`/crypto/liquidity_pool_stable_coin?page=${page}&limit=${perPage}`)
    .then((res) => {
      dispatch({
        type: actionType,
        liquidityPoolsStableCoin: res.data.data
      });
    })
    .catch((err) => {
      // console.log(err);
    });
};

export const verifyToken = () => {
  const token = localStorage.getItem('hootdex_secretcookie_wallet');
  try {
    const parsedToken = JSON.parse(token);
    const decoded = jwtDecode(parsedToken); // Replace 'your-secret-key' with your actual secret key
    const currentTimestamp = Math.floor(Date.now() / 1000); // Get the current timestamp in seconds

    if (decoded.exp && decoded.exp < currentTimestamp) {
      return false;
    }

    // Token is valid
    return true;
  } catch (error) {
    // Token is invalid
    return false;
  }
};
